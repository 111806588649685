<template>
  <div id="analysis-report">
    <!-- <div class="export-btn">
			<a-button type="primary" @click="onOutTable">导出</a-button>
		</div> -->
    <div class="main" ref="table1">
      <div class="title-shell" style="margin-bottom: 24px">
        <p class="title">{{ title }}污染源在线监控数据日数据分析报告</p>
      </div>
      <div class="select-time" style="margin-bottom: 25px">
        <!-- <div class="left-text">{{ title }}{{ navTitle }}</div> -->
        <div class="left-text"></div>
        <a-date-picker
          :format="dateFormat"
          :value="moment(TOTALDAY)"
          style="width: 120px"
          @change="onChange"
        />
      </div>
      <div class="table">
        <p class="title">一、日数据分析总报表({{ JZS }}个基站)</p>
        <div class="table-content-lsit">
          <div class="table-content">
            <a-table
              size="small"
              :loading="loading1"
              bordered
              :pagination="false"
              :row-key="
                (r, i) => {
                  return i.toString();
                }
              "
              :columns="columns"
              :data-source="data"
            ></a-table>
          </div>
        </div>
      </div>
      <div class="table">
        <p class="title">二、数据超标基站详情</p>
        <div class="table-content-lsit">
          <p class="table-content-title">
            1、废气 {{ number1 }}个基站数据超标（mg/m³）
          </p>
          <div class="table-content" style="padding-bottom: 8px">
            <a-table
              size="small"
              :loading="loading2"
              bordered
              :pagination="false"
              :row-key="
                (r, i) => {
                  return i.toString();
                }
              "
              :columns="columns1"
              :data-source="data1"
            ></a-table>
          </div>
          <p class="table-content-title">
            2、废水 {{ number2 }}个基站数据超标（mg/L）
          </p>
          <div class="table-content">
            <a-table
              size="small"
              :loading="loading3"
              bordered
              :pagination="false"
              :row-key="
                (r, i) => {
                  return i.toString();
                }
              "
              :columns="columns1"
              :data-source="data2"
            ></a-table>
          </div>
          <p class="table-content-title">
            3、扬尘 {{ number8 }}个基站数据超标（ug/m³）
          </p>
          <div class="table-content">
            <a-table
              size="small"
              :loading="loading9"
              bordered
              :pagination="false"
              :row-key="
                (r, i) => {
                  return i.toString();
                }
              "
              :columns="columns1"
              :data-source="data8"
            ></a-table>
          </div>
          <p class="table-content-title">
            4、VOCs {{ number9 }}个基站数据超标（mg/m³）
          </p>
          <div class="table-content">
            <a-table
              size="small"
              :loading="loading10"
              bordered
              :pagination="false"
              :row-key="
                (r, i) => {
                  return i.toString();
                }
              "
              :columns="columns1"
              :data-source="data9"
            ></a-table>
          </div>
        </div>
      </div>
      <div class="table">
        <p class="title">三、数据异常基站详情</p>
        <div class="table-content-lsit">
          <p class="table-content-title">
            1、废气{{ number3 }}个基站数据异常（mg/m³）
          </p>
          <div class="table-content" style="padding-bottom: 8px">
            <a-table
              size="small"
              :loading="loading4"
              bordered
              :pagination="false"
              :row-key="
                (r, i) => {
                  return i.toString();
                }
              "
              :columns="columns2"
              :data-source="data3"
            ></a-table>
          </div>
          <p class="table-content-title">
            2、废水{{ number4 }}个基站数据异常（mg/L）
          </p>
          <div class="table-content">
            <a-table
              size="small"
              :loading="loading5"
              bordered
              :pagination="false"
              :row-key="
                (r, i) => {
                  return i.toString();
                }
              "
              :columns="columns2"
              :data-source="data4"
            ></a-table>
          </div>
          <p class="table-content-title">
            3、扬尘 {{ number10 }}个基站数据异常（ug/m³）
          </p>
          <div class="table-content">
            <a-table
              size="small"
              :loading="loading11"
              bordered
              :pagination="false"
              :row-key="
                (r, i) => {
                  return i.toString();
                }
              "
              :columns="columns2"
              :data-source="data10"
            ></a-table>
          </div>
          <p class="table-content-title">
            4、VOCs {{ number11 }}个基站数据异常（mg/m³）
          </p>
          <div class="table-content">
            <a-table
              size="small"
              :loading="loading12"
              bordered
              :pagination="false"
              :row-key="
                (r, i) => {
                  return i.toString();
                }
              "
              :columns="columns2"
              :data-source="data11"
            ></a-table>
          </div>
        </div>
      </div>
      <div class="table">
        <p class="title">四、数据缺失基站详情</p>
        <div class="table-content-lsit">
          <p class="table-content-title">
            1、废气{{ number51 }}个、废水{{ number52 }}个、扬尘{{
              number53
            }}个、VOCs{{ number54 }}个
          </p>
          <div class="table-content" style="padding-bottom: 8px">
            <a-table
              size="small"
              :loading="loading6"
              bordered
              :pagination="false"
              :row-key="
                (r, i) => {
                  return i.toString();
                }
              "
              :columns="columns3"
              :data-source="data5"
            ></a-table>
          </div>
        </div>
      </div>
      <div class="table">
        <p class="title">五、停运基站详情</p>
        <div class="table-content-lsit">
          <p class="table-content-title">1、废气{{ number6 }}个基站停运</p>
          <div class="table-content" style="padding-bottom: 8px">
            <a-table
              size="small"
              :loading="loading7"
              bordered
              :pagination="false"
              :row-key="
                (r, i) => {
                  return i.toString();
                }
              "
              :columns="columns4"
              :data-source="data6"
            ></a-table>
          </div>
          <p class="table-content-title">2、废水{{ number7 }}个基站停运</p>
          <div class="table-content">
            <a-table
              size="small"
              :loading="loading8"
              bordered
              :pagination="false"
              :row-key="
                (r, i) => {
                  return i.toString();
                }
              "
              :columns="columns4"
              :data-source="data7"
            ></a-table>
          </div>
          <p class="table-content-title">3、扬尘 {{ number12 }}个基站停运</p>
          <div class="table-content">
            <a-table
              size="small"
              :loading="loading13"
              bordered
              :pagination="false"
              :row-key="
                (r, i) => {
                  return i.toString();
                }
              "
              :columns="columns4"
              :data-source="data12"
            ></a-table>
          </div>
          <p class="table-content-title">4、VOCs {{ number13 }}个基站停运</p>
          <div class="table-content">
            <a-table
              size="small"
              :loading="loading14"
              bordered
              :pagination="false"
              :row-key="
                (r, i) => {
                  return i.toString();
                }
              "
              :columns="columns4"
              :data-source="data13"
            ></a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

// import html2canvas from 'html2canvas';
// import JsPDF from 'jspdf';

import { http } from "../utils/Http.js";

import dayjs from "dayjs";
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
dayjs.extend(quarterOfYear);

//日数据分析总报表
const columns = [
  {
    title: "序号",
    width: 60,
    align: "center",
    customRender: (text, record, index) => {
      return index + 1;
    },
  },
  {
    title: "类别",
    dataIndex: "type",
    align: "center",
    customRender: (text, record, index) => {
      let name = "";

      // 1正常
      // 2、超标
      // 3、异常
      // 4、缺失
      // 5、停运

      if (text == 1) {
        name = "正常基站";
      } else if (text == 2) {
        name = "数据超标基站";
      } else if (text == 3) {
        name = "数据异常基站";
      } else if (text == 4) {
        name = "数据缺失基站";
      } else if (text == 5) {
        name = "停运基站";
      }
      return name;
    },
  },
  {
    title: "废气",
    dataIndex: "FQ",
    align: "center",
  },
  {
    title: "废水",
    dataIndex: "FS",
    align: "center",
  },
  {
    title: "扬尘",
    dataIndex: "YC",
    align: "center",
  },
  {
    title: "VOCs",
    dataIndex: "VO",
    align: "center",
  },
  {
    title: "合计",
    dataIndex: "HJ",
    align: "center",
  },
];

//数据超标基站详情
const columns1 = [
  {
    title: "序号",
    width: 60,
    align: "center",
    customRender: (text, record, index) => {
      return index + 1;
    },
  },
  {
    title: "企业名称",
    dataIndex: "EnterpriseMC",
    align: "center",
  },
  {
    title: "基站名称",
    width: 100,
    dataIndex: "station_name",
    align: "center",
  },
  {
    title: "监测因子",
    width: 100,
    dataIndex: "ItemName",
    align: "center",
  },
  {
    title: "超标时长",
    width: 80,
    dataIndex: "SC",
    align: "center",
  },
  {
    title: "超标时段",
    width: 190,
    dataIndex: "SD",
    align: "center",
  },
  {
    title: "排放标准",
    width: 80,
    dataIndex: "PCBZ",
    align: "center",
  },
  {
    title: "超标最大值",
    width: 100,
    dataIndex: "YCZ",
    align: "center",
  },
  {
    title: "处理说明",
    width: 100,
    dataIndex: "YL_3",
    align: "center",
  }
];

//数据异常基站详情
const columns2 = [
  {
    title: "序号",
    width: 60,
    align: "center",
    customRender: (text, record, index) => {
      return index + 1;
    },
  },
  {
    title: "企业名称",
    dataIndex: "EnterpriseMC",
    align: "center",
  },
  {
    title: "基站名称",
    width: 100,
    dataIndex: "station_name",
    align: "center",
  },
  {
    title: "监测因子",
    width: 100,
    dataIndex: "ItemName",
    align: "center",
  },
  {
    title: "异常时长",
    width: 80,
    dataIndex: "SC",
    align: "center",
  },
  {
    title: "异常时段",
    width: 190,
    dataIndex: "SD",
    align: "center",
  },
  {
    title: "异常类型",
    width: 80,
    dataIndex: "YCLX",
    align: "center",
    customRender: (text, record, index) => {
      // (1:最大值 2：最小值)
      let name = "";

      if (text == 1) {
        name = "最大值";
      } else if (text == 2) {
        name = "最小值";
      } else if (text == 3) {
        name = "固定值";
      }
      return name;
    },
  },
  {
    title: "异常数值",
    width: 100,
    dataIndex: "YCZ",
    align: "center",
  },
  {
    title: "处理说明",
    width: 100,
    dataIndex: "YL_3",
    align: "center"
  }
];

//数据缺失基站详情
const columns3 = [
  {
    title: "序号",
    width: 60,
    align: "center",
    customRender: (text, record, index) => {
      return index + 1;
    },
  },
  {
    title: "企业名称",
    dataIndex: "EnterpriseMC",
    align: "center",
  },
  {
    title: "基站名称",
    width: 220,
    dataIndex: "station_name",
    align: "center",
  },
  {
    title: "传输率",
    width: 80,
    dataIndex: "CSL",
    align: "center"
  },
  {
    title: "缺失时段",
    width: 180,
    dataIndex: "SD",
    align: "center"
  },
  {
    title: "备注",
    width: 160,
    dataIndex: "BZ",
    align: "center"
  }
];

//基站停运列表
const columns4 = [
  {
    title: "序号",
    width: 60,
    align: "center",
    customRender: (text, record, index) => {
      return index + 1;
    },
  },
  {
    title: "企业名称",
    dataIndex: "EnterpriseMC",
    align: "center",
  },
  {
    title: "基站名称",
    width: 220,
    dataIndex: "station_name",
    align: "center",
  },
  {
    title: "停运时段",
    dataIndex: "TYSD",
    width: 280,
    align: "center",
  },
  {
    title: "停运报告",
    width: 80,
    dataIndex: "TYBB",
    align: "center",
    customRender: (text, record, index) => {
      let name = "";

      //1：有，其它：无

      if (text == 1) {
        name = "有";
      } else {
        name = "无";
      }
      return name;
    },
  },
  {
    title: "停运原因",
    width: 80,
    dataIndex: "TYYY",
    // ellipsis: true,
    align: "center",
  },
];

export default {
  name: "Home",
  components: {},
  props: {
    isExport: false,
    currentdate:null
  },
  data() {
    return {
      moment,
      dateFormat: "YYYY-MM-DD",
      data: [],
      columns,
      columns1,
      columns2,
      columns3,
      columns4,

      TOTALDAY: dayjs(new Date()).format("YYYY-MM-DD"), //统计时间
      title: "",
      navTitle: "",
      JZS: "", //总基站数

      // 日数据基站超标
      data1: [],
      number1: 0,
      data2: [],
      number2: 0,
      data8: [],
      number8: 0,
      data9: [],
      number9: 0,

      // 日数据基站异常
      data3: [],
      number3: 0,
      data4: [],
      number4: 0,
      data10: [],
      number10: 0,
      data11: [],
      number11: 0,

      //基站缺失详情
      data5: [],
      number51: 0,
      number52: 0,
      number53: 0,
      number54: 0,

      //基站停运列表
      data6: [],
      number6: 0,

      data7: [],
      number7: 0,

      data12: [],
      number12: 0,

      data13: [],
      number13: 0,

      loading1: false,
      loading2: false,
      loading3: false,
      loading4: false,
      loading5: false,
      loading6: false,
      loading7: false,
      loading8: false,
      loading9: false,
      loading10: false,
      loading11: false,
      loading12: false,
      loading13: false,
      loading14: false,

      loadinga1: false,
      loadinga2: false,
      loadinga3: false,
      loadinga4: false,
      loadinga5: false,
      loadinga6: false,
      loadinga7: false,
      loadinga8: false,
      loadinga9: false,
      loadinga10: false,
      loadinga11: false,
      loadinga12: false,
      loadinga13: false,
      loadinga14: false,
    };
  },

  mounted() {
    if(this.currentdate!=null){
      this.TOTALDAY=this.currentdate
    }
    // //日统计
    // this.HJJC_DAYTOTAL();

    // // 日数据基站异常
    // // 报警类型（1异常2：超标）
    // // 基站类型		1废气 2废水 3扬尘 4VOCS
    // this.HJJC_DAYTOTAL_ABNORMAL_LIST(2, 1);
    // this.HJJC_DAYTOTAL_ABNORMAL_LIST(2, 2);
    // this.HJJC_DAYTOTAL_ABNORMAL_LIST(1, 1);
    // this.HJJC_DAYTOTAL_ABNORMAL_LIST(1, 2);

    // // 基站缺失详情
    // this.HJJC_DAYTOTAL_LACK_LIST();

    // //基站停运列表
    // // 基站类型	1废气  2废水  3扬尘  4VOCS
    // this.HJJC_DAYTOTAL_OUTAGE_LIST(1);

    // this.HJJC_DAYTOTAL_OUTAGE_LIST(2);

    this.callInterface();

    var loggedIn = this.$store.state.loggedIn;

    // let title = `${loggedIn.SFMC}${loggedIn.SMC}${loggedIn.XMC}`;
    let title = "";

    if (loggedIn.SFMC) {
      title = loggedIn.SFMC;
    }

    if (loggedIn.SMC) {
      title = loggedIn.SMC;
    }

    if (loggedIn.XMC) {
      title = loggedIn.XMC;
    }

    if (title == null) {
      this.title = "";
    } else {
      this.title = title;
    }

    let navTitle = `${loggedIn.SFMC}${loggedIn.SMC}${loggedIn.XMC}${loggedIn.EnterpriseMC}`;
    if (navTitle == null) {
      // this.navTitle = "";
    } else {
      // this.navTitle = navTitle;
    }
    if (this.isExport) {
      document.title =
        this.title + "污染源在线监控数据日数据分析报告" + this.TOTALDAY;
    }

    this.complete();
    // this.navTitle = `${loggedIn.SFMC}${loggedIn.SMC}${loggedIn.XMC}${loggedIn.EnterpriseMC}` || '';
    // console.log(this.title);
    // console.log(this.navTitle);
    // console.log(loggedIn);
  },

  methods: {
    //选择日期
    onChange(date, value) {
      // console.log(value);
      this.TOTALDAY = value;
      this.$emit('onDateChange',this.TOTALDAY)
      if (value) {
        this.callInterface();
      }
    },

    callInterface() {
      //日统计
      this.HJJC_DAYTOTAL();

      // 日数据基站异常
      // 报警类型（1异常2：超标）
      // 基站类型		1废气 2废水 3扬尘 4VOCS
      this.HJJC_DAYTOTAL_ABNORMAL_LIST(2, 1);
      this.HJJC_DAYTOTAL_ABNORMAL_LIST(2, 2);
      this.HJJC_DAYTOTAL_ABNORMAL_LIST(2, 3);
      this.HJJC_DAYTOTAL_ABNORMAL_LIST(2, 4);

      this.HJJC_DAYTOTAL_ABNORMAL_LIST(1, 1);
      this.HJJC_DAYTOTAL_ABNORMAL_LIST(1, 2);
      this.HJJC_DAYTOTAL_ABNORMAL_LIST(1, 3);
      this.HJJC_DAYTOTAL_ABNORMAL_LIST(1, 4);

      // 基站缺失详情
      this.HJJC_DAYTOTAL_LACK_LIST();

      //基站停运列表
      // 基站类型	1废气  2废水  3扬尘  4VOCS
      this.HJJC_DAYTOTAL_OUTAGE_LIST(1);
      this.HJJC_DAYTOTAL_OUTAGE_LIST(2);
      this.HJJC_DAYTOTAL_OUTAGE_LIST(3);
      this.HJJC_DAYTOTAL_OUTAGE_LIST(4);
    },

    //日统计接口
    HJJC_DAYTOTAL() {
      this.loading1 = true;
      var me = this;
      http({
        url: "/data/q30/HJJC_DAYTOTAL.aspx",
        data: {
          CKM: me.$store.state.loggedIn.CKM,
          TOTALDAY: me.TOTALDAY,
          STATIONTYPE: "",
        },
        success(obj) {
          console.log(obj);
          me.data = obj.Data;

          me.loading1 = false;

          me.loadinga1 = true;
          // me.complete(me.loadinga1, 1);

          if (obj.Data1 && obj.Data1.length > 0) {
            me.JZS = obj.Data1[0].JZS;
          }
        },
        failure(obj) {
          me.loading1 = false;
        },
        me,
      });
    },

    // 日数据基站异常
    HJJC_DAYTOTAL_ABNORMAL_LIST(BJLX, STATIONTYPE) {
      var me = this;

      // 报警类型    （1异常  2：超标）
      // 基站类型		1废气 2废水 3扬尘 4VOCS
      if (BJLX == 2 && STATIONTYPE == 1) {
        me.loading2 = true;
      }

      if (BJLX == 2 && STATIONTYPE == 2) {
        me.loading3 = true;
      }

      if (BJLX == 2 && STATIONTYPE == 3) {
        me.loading9 = true;
      }

      if (BJLX == 2 && STATIONTYPE == 4) {
        me.loading10 = true;
      }

      if (BJLX == 1 && STATIONTYPE == 1) {
        me.loading4 = true;
      }

      if (BJLX == 1 && STATIONTYPE == 2) {
        me.loading5 = true;
      }

      if (BJLX == 1 && STATIONTYPE == 3) {
        me.loading11 = true;
      }

      if (BJLX == 1 && STATIONTYPE == 4) {
        me.loading12 = true;
      }

      http({
        url: "/data/q30/HJJC_DAYTOTAL_ABNORMAL_LIST.aspx",
        data: {
          CKM: me.$store.state.loggedIn.CKM,
          TOTALDAY: me.TOTALDAY,
          BJLX: BJLX,
          STATIONTYPE: STATIONTYPE,
          // PAGESIZE: 1,
          // PAGENUMBER: 1,
        },
        success(obj) {
          console.log(obj);
          // 报警类型    （1异常  2：超标）
          // 基站类型		1废气 2废水 3扬尘 4VOCS

          // // 日数据基站超标
          // data1: [],
          // number1: 0,
          // data2: [],
          // number2: 0,
          // data8: [],
          // number8: 0,
          // data9: [],
          // number9: 0,

          if (BJLX == 2 && STATIONTYPE == 1) {
            me.loading2 = false;

            me.loadinga2 = true;
            // me.complete(me.loadinga2, 2);

            me.data1 = obj.Data;
            if (obj.Data1 && obj.Data1.length > 0) {
              me.number1 = obj.Data1[0].JZS;
            }
          }

          if (BJLX == 2 && STATIONTYPE == 2) {
            me.loading3 = false;
            me.loadinga3 = true;
            // me.complete(me.loadinga3, 3);

            me.data2 = obj.Data;
            if (obj.Data1 && obj.Data1.length > 0) {
              me.number2 = obj.Data1[0].JZS;
            }
          }

          if (BJLX == 2 && STATIONTYPE == 3) {
            me.loading9 = false;

            me.loadinga9 = true;
            // me.complete(me.loadinga9, 9);

            me.data8 = obj.Data;
            if (obj.Data1 && obj.Data1.length > 0) {
              me.number8 = obj.Data1[0].JZS;
            }
          }

          if (BJLX == 2 && STATIONTYPE == 4) {
            me.loading10 = false;

            me.loadinga10 = true;
            // me.complete(me.loadinga10, 10);

            me.data9 = obj.Data;
            if (obj.Data1 && obj.Data1.length > 0) {
              me.number9 = obj.Data1[0].JZS;
            }
          }

          // // 日数据基站异常
          // data3: [],
          // number3: 0,
          // data4: [],
          // number4: 0,
          // data10: [],
          // number10: 0,
          // data11: [],
          // number11: 0,
          if (BJLX == 1 && STATIONTYPE == 1) {
            me.loading4 = false;

            me.loadinga4 = true;
            // me.complete(me.loadinga4, 4);

            me.data3 = obj.Data;
            if (obj.Data1 && obj.Data1.length > 0) {
              me.number3 = obj.Data1[0].JZS;
            }
          }

          if (BJLX == 1 && STATIONTYPE == 2) {
            me.loading5 = false;

            me.loadinga5 = true;
            // me.complete(me.loadinga5, 5);

            me.data4 = obj.Data;
            if (obj.Data1 && obj.Data1.length > 0) {
              me.number4 = obj.Data1[0].JZS;
            }
          }

          if (BJLX == 1 && STATIONTYPE == 3) {
            me.loading11 = false;

            me.loadinga11 = true;
            // me.complete(me.loadinga11, 11);

            me.data10 = obj.Data;
            if (obj.Data1 && obj.Data1.length > 0) {
              me.number10 = obj.Data1[0].JZS;
            }
          }

          if (BJLX == 1 && STATIONTYPE == 4) {
            me.loading12 = false;

            me.loadinga12 = true;
            // me.complete(me.loadinga12, 12);

            me.data11 = obj.Data;
            if (obj.Data1 && obj.Data1.length > 0) {
              me.number11 = obj.Data1[0].JZS;
            }
          }
        },
        failure(obj) {
          // 报警类型    （1异常  2：超标）
          // 基站类型		1废气 2废水 3扬尘 4VOCS
          if (BJLX == 2 && STATIONTYPE == 1) {
            me.loading2 = false;
          }

          if (BJLX == 2 && STATIONTYPE == 2) {
            me.loading3 = false;
          }

          if (BJLX == 2 && STATIONTYPE == 3) {
            me.loading9 = false;
          }

          if (BJLX == 2 && STATIONTYPE == 4) {
            me.loading10 = false;
          }

          if (BJLX == 1 && STATIONTYPE == 1) {
            me.loading4 = false;
          }

          if (BJLX == 1 && STATIONTYPE == 2) {
            me.loading5 = false;
          }

          if (BJLX == 1 && STATIONTYPE == 3) {
            me.loading11 = false;
          }

          if (BJLX == 1 && STATIONTYPE == 4) {
            me.loading12 = false;
          }
        },
        me,
      });
    },

    //基站缺失详情
    HJJC_DAYTOTAL_LACK_LIST() {
      var me = this;
      me.loading6 = true;
      http({
        url: "/data/q30/HJJC_DAYTOTAL_LACK_LIST.aspx",
        data: {
          CKM: me.$store.state.loggedIn.CKM,
          TOTALDAY: me.TOTALDAY,
          // PAGESIZE: 1,
          // PAGENUMBER: 1,
        },
        success(obj) {
          console.log(obj);
          me.data5 = obj.Data;
          me.loading6 = false;

          me.loadinga6 = true;
          // me.complete(me.loadinga6, 6);

          if (obj.Data1 && obj.Data1.length > 0) {
            for (var i = 0; i < obj.Data1.length; i++) {
              if (obj.Data1[i].STATIONTYPE == 1) {
                me.number51 = obj.Data1[i].JZS;
              } else if (obj.Data1[i].STATIONTYPE == 2) {
                me.number52 = obj.Data1[i].JZS;
              } else if (obj.Data1[i].STATIONTYPE == 3) {
                me.number53 = obj.Data1[i].JZS;
              } else if (obj.Data1[i].STATIONTYPE == 4) {
                me.number54 = obj.Data1[i].JZS;
              }
            }
          }
        },
        failure(obj) {
          me.loading6 = false;
        },
        me,
      });
    },

    // 基站停运列表
    HJJC_DAYTOTAL_OUTAGE_LIST(STATIONTYPE) {
      var me = this;

      if (STATIONTYPE == 1) {
        me.loading7 = true;
      } else if (STATIONTYPE == 2) {
        me.loading8 = true;
      } else if (STATIONTYPE == 3) {
        me.loadinga13 = true;
      } else if (STATIONTYPE == 4) {
        me.loadinga14 = true;
      }

      http({
        url: "/data/q30/HJJC_DAYTOTAL_OUTAGE_LIST.aspx",
        data: {
          CKM: me.$store.state.loggedIn.CKM,
          TOTALDAY: me.TOTALDAY,
          STATIONTYPE: STATIONTYPE,
          // PAGESIZE: 1,
          // PAGENUMBER: 1,
        },
        success(obj) {
          console.log(obj);
          // 基站类型	1废气  2废水  3扬尘  4VOCS
          if (STATIONTYPE == 1) {
            me.data6 = obj.Data;
            me.loading7 = false;

            me.loadinga7 = true;
            // me.complete(me.loadinga7, 7);

            if (obj.Data1 && obj.Data1.length > 0) {
              me.number6 = obj.Data1[0].JZS;
            }
          } else if (STATIONTYPE == 2) {
            me.data7 = obj.Data;
            me.loading8 = false;

            me.loadinga8 = true;
            // me.complete(me.loadinga8, 8);

            if (obj.Data1 && obj.Data1.length > 0) {
              me.number7 = obj.Data1[0].JZS;
            }
          } else if (STATIONTYPE == 3) {
            me.loading13 = false;

            me.loadinga13 = true;
            // me.complete(me.loadinga13, 13);

            me.data12 = obj.Data;
            if (obj.Data1 && obj.Data1.length > 0) {
              me.number12 = obj.Data1[0].JZS;
            }
          } else if (STATIONTYPE == 4) {
            me.loading14 = false;

            me.loadinga14 = true;
            // me.complete(me.loadinga14, 14);

            me.data13 = obj.Data;
            if (obj.Data1 && obj.Data1.length > 0) {
              me.number13 = obj.Data1[0].JZS;
            }
          }

          // data12: [],
          // number12: 0,

          // data13: [],
          // number13: 0,
        },
        failure(obj) {
          if (STATIONTYPE == 1) {
            me.loading7 = false;
          } else if (STATIONTYPE == 2) {
            me.loading8 = false;
          } else if (STATIONTYPE == 3) {
            me.loadinga13 = false;
          } else if (STATIONTYPE == 4) {
            me.loadinga14 = false;
          }
        },
        me,
      });
    },

    complete() {
      var me = this;
      var flag = false;
      var time = setInterval(function () {
        if (
          me.loadinga1 &&
          me.loadinga2 &&
          me.loadinga3 &&
          me.loadinga4 &&
          me.loadinga5 &&
          me.loadinga6 &&
          me.loadinga7 &&
          me.loadinga8 &&
          me.loadinga9 &&
          me.loadinga10 &&
          me.loadinga11 &&
          me.loadinga12 &&
          me.loadinga13 &&
          me.loadinga14
        ) {
          flag = true;
          me.$emit("export");
        }

        if (flag) {
          clearInterval(time);
        }
      }, 1000);
    },

    // //导出
    // onOutTable() {
    // 	let me = this;

    // 	window.pageYOffset = 0;
    // 	document.documentElement.scrollTop = 0;
    // 	document.body.scrollTop = 0;

    // 	html2canvas(this.$refs.table1, {
    // 		allowTaint: true,
    // 		scale: 2,
    // 		dpi: 182, //导出图片清晰度
    // 	}).then(function(canvas) {
    // 		let contentWidth = canvas.width
    // 		let contentHeight = canvas.height
    // 		//一页pdf显示html页面生成的canvas高度;
    // 		let pageHeight = contentWidth / 592.28 * 841.89
    // 		//未生成pdf的html页面高度
    // 		let leftHeight = contentHeight
    // 		//页面偏移
    // 		let position = 0
    // 		let imgWidth = 595.28
    // 		let imgHeight = 592.28 / contentWidth * contentHeight
    // 		//返回图片dataURL，参数：图片格式和清晰度(0-1)
    // 		let pageData = canvas.toDataURL('image/jpeg', 1.0)
    // 		//方向默认竖直，尺寸ponits，格式 a4纸 [595.28,841.89]
    // 		let PDF = new JsPDF('', 'pt', 'a4')
    // 		//有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
    // 		//当内容未超过pdf一页显示的范围，无需分页
    // 		if (leftHeight < pageHeight) {
    // 			//addImage将图片添加到pdf中
    // 			//addImage中间两个参数控制x、y边距，
    // 			//后两个参数控制添加图片的尺寸，此处将页面高度按照a4纸宽高比列进行压缩
    // 			PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
    // 		} else {
    // 			while (leftHeight > 0) {
    // 				PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
    // 				leftHeight -= pageHeight
    // 				position -= 841.89
    // 				//避免添加空白页
    // 				if (leftHeight > 0) {
    // 					//addPage()添加pdf页数
    // 					PDF.addPage()
    // 				}
    // 			}
    // 		}
    // 		//保存名称
    // 		PDF.save(`${me.title}污染源在线监控数据日数据分析报告.pdf`)
    // 	})
    // }
  },
};
</script>
<style lang="scss">
.ant-empty-normal {
  margin: 0px;
}
.ant-empty-image {
  margin-bottom: 0px;
}
</style>
<style lang="scss" scoped>
#analysis-report {
  // width: 1200px;
  // margin: 0 auto;
  // padding: 20px 0 0;
  // color: #181818;
  // position: relative;

  // .export-btn {
  // 	position: absolute;
  // 	top: 60px;
  // 	right: 40px;

  // 	button {
  // 		background-color: #1876FF;
  // 	}
  // }

  .main {
    padding: 40px;
    background-color: #ffffff;

    .title-shell {
      text-align: center;
      height: 32px;
      font-size: 18px;
      font-weight: 500;
      line-height: 32px;
      display: flex;
      align-items: center;

      .title {
        text-align: center;
        padding-left: 72px;
        flex: 1;
      }

      .ant-btn {
        width: 72px;
        height: 32px;
        line-height: 30px;
        color: #ffffff;
        font-size: 16px;
        background-color: #1876ff;
      }
    }

    .select-time {
      display: flex;
      align-items: center;
      font-size: 18px;

      .left-text {
        flex: 1;
      }
    }

    .table {
      padding-bottom: 40px;

      .title {
        height: 22px;
        font-size: 16px;
        line-height: 22px;
        font-weight: 700;
      }

      .table-content-lsit {
        .table-content-title {
          margin-top: 12px;
          font-size: 14px;
          height: 20px;
          line-height: 20px;
        }

        .table-content {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
