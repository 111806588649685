var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"analysis-report"}},[_c('div',{ref:"table1",staticClass:"main"},[_c('div',{staticClass:"title-shell",staticStyle:{"margin-bottom":"24px"}},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.title)+"污染源在线监控数据日数据分析报告")])]),_c('div',{staticClass:"select-time",staticStyle:{"margin-bottom":"25px"}},[_c('div',{staticClass:"left-text"}),_c('a-date-picker',{staticStyle:{"width":"120px"},attrs:{"format":_vm.dateFormat,"value":_vm.moment(_vm.TOTALDAY)},on:{"change":_vm.onChange}})],1),_c('div',{staticClass:"table"},[_c('p',{staticClass:"title"},[_vm._v("一、日数据分析总报表("+_vm._s(_vm.JZS)+"个基站)")]),_c('div',{staticClass:"table-content-lsit"},[_c('div',{staticClass:"table-content"},[_c('a-table',{attrs:{"size":"small","loading":_vm.loading1,"bordered":"","pagination":false,"row-key":function (r, i) {
                  return i.toString();
                },"columns":_vm.columns,"data-source":_vm.data}})],1)])]),_c('div',{staticClass:"table"},[_c('p',{staticClass:"title"},[_vm._v("二、数据超标基站详情")]),_c('div',{staticClass:"table-content-lsit"},[_c('p',{staticClass:"table-content-title"},[_vm._v(" 1、废气 "+_vm._s(_vm.number1)+"个基站数据超标（mg/m³） ")]),_c('div',{staticClass:"table-content",staticStyle:{"padding-bottom":"8px"}},[_c('a-table',{attrs:{"size":"small","loading":_vm.loading2,"bordered":"","pagination":false,"row-key":function (r, i) {
                  return i.toString();
                },"columns":_vm.columns1,"data-source":_vm.data1}})],1),_c('p',{staticClass:"table-content-title"},[_vm._v(" 2、废水 "+_vm._s(_vm.number2)+"个基站数据超标（mg/L） ")]),_c('div',{staticClass:"table-content"},[_c('a-table',{attrs:{"size":"small","loading":_vm.loading3,"bordered":"","pagination":false,"row-key":function (r, i) {
                  return i.toString();
                },"columns":_vm.columns1,"data-source":_vm.data2}})],1),_c('p',{staticClass:"table-content-title"},[_vm._v(" 3、扬尘 "+_vm._s(_vm.number8)+"个基站数据超标（ug/m³） ")]),_c('div',{staticClass:"table-content"},[_c('a-table',{attrs:{"size":"small","loading":_vm.loading9,"bordered":"","pagination":false,"row-key":function (r, i) {
                  return i.toString();
                },"columns":_vm.columns1,"data-source":_vm.data8}})],1),_c('p',{staticClass:"table-content-title"},[_vm._v(" 4、VOCs "+_vm._s(_vm.number9)+"个基站数据超标（mg/m³） ")]),_c('div',{staticClass:"table-content"},[_c('a-table',{attrs:{"size":"small","loading":_vm.loading10,"bordered":"","pagination":false,"row-key":function (r, i) {
                  return i.toString();
                },"columns":_vm.columns1,"data-source":_vm.data9}})],1)])]),_c('div',{staticClass:"table"},[_c('p',{staticClass:"title"},[_vm._v("三、数据异常基站详情")]),_c('div',{staticClass:"table-content-lsit"},[_c('p',{staticClass:"table-content-title"},[_vm._v(" 1、废气"+_vm._s(_vm.number3)+"个基站数据异常（mg/m³） ")]),_c('div',{staticClass:"table-content",staticStyle:{"padding-bottom":"8px"}},[_c('a-table',{attrs:{"size":"small","loading":_vm.loading4,"bordered":"","pagination":false,"row-key":function (r, i) {
                  return i.toString();
                },"columns":_vm.columns2,"data-source":_vm.data3}})],1),_c('p',{staticClass:"table-content-title"},[_vm._v(" 2、废水"+_vm._s(_vm.number4)+"个基站数据异常（mg/L） ")]),_c('div',{staticClass:"table-content"},[_c('a-table',{attrs:{"size":"small","loading":_vm.loading5,"bordered":"","pagination":false,"row-key":function (r, i) {
                  return i.toString();
                },"columns":_vm.columns2,"data-source":_vm.data4}})],1),_c('p',{staticClass:"table-content-title"},[_vm._v(" 3、扬尘 "+_vm._s(_vm.number10)+"个基站数据异常（ug/m³） ")]),_c('div',{staticClass:"table-content"},[_c('a-table',{attrs:{"size":"small","loading":_vm.loading11,"bordered":"","pagination":false,"row-key":function (r, i) {
                  return i.toString();
                },"columns":_vm.columns2,"data-source":_vm.data10}})],1),_c('p',{staticClass:"table-content-title"},[_vm._v(" 4、VOCs "+_vm._s(_vm.number11)+"个基站数据异常（mg/m³） ")]),_c('div',{staticClass:"table-content"},[_c('a-table',{attrs:{"size":"small","loading":_vm.loading12,"bordered":"","pagination":false,"row-key":function (r, i) {
                  return i.toString();
                },"columns":_vm.columns2,"data-source":_vm.data11}})],1)])]),_c('div',{staticClass:"table"},[_c('p',{staticClass:"title"},[_vm._v("四、数据缺失基站详情")]),_c('div',{staticClass:"table-content-lsit"},[_c('p',{staticClass:"table-content-title"},[_vm._v(" 1、废气"+_vm._s(_vm.number51)+"个、废水"+_vm._s(_vm.number52)+"个、扬尘"+_vm._s(_vm.number53)+"个、VOCs"+_vm._s(_vm.number54)+"个 ")]),_c('div',{staticClass:"table-content",staticStyle:{"padding-bottom":"8px"}},[_c('a-table',{attrs:{"size":"small","loading":_vm.loading6,"bordered":"","pagination":false,"row-key":function (r, i) {
                  return i.toString();
                },"columns":_vm.columns3,"data-source":_vm.data5}})],1)])]),_c('div',{staticClass:"table"},[_c('p',{staticClass:"title"},[_vm._v("五、停运基站详情")]),_c('div',{staticClass:"table-content-lsit"},[_c('p',{staticClass:"table-content-title"},[_vm._v("1、废气"+_vm._s(_vm.number6)+"个基站停运")]),_c('div',{staticClass:"table-content",staticStyle:{"padding-bottom":"8px"}},[_c('a-table',{attrs:{"size":"small","loading":_vm.loading7,"bordered":"","pagination":false,"row-key":function (r, i) {
                  return i.toString();
                },"columns":_vm.columns4,"data-source":_vm.data6}})],1),_c('p',{staticClass:"table-content-title"},[_vm._v("2、废水"+_vm._s(_vm.number7)+"个基站停运")]),_c('div',{staticClass:"table-content"},[_c('a-table',{attrs:{"size":"small","loading":_vm.loading8,"bordered":"","pagination":false,"row-key":function (r, i) {
                  return i.toString();
                },"columns":_vm.columns4,"data-source":_vm.data7}})],1),_c('p',{staticClass:"table-content-title"},[_vm._v("3、扬尘 "+_vm._s(_vm.number12)+"个基站停运")]),_c('div',{staticClass:"table-content"},[_c('a-table',{attrs:{"size":"small","loading":_vm.loading13,"bordered":"","pagination":false,"row-key":function (r, i) {
                  return i.toString();
                },"columns":_vm.columns4,"data-source":_vm.data12}})],1),_c('p',{staticClass:"table-content-title"},[_vm._v("4、VOCs "+_vm._s(_vm.number13)+"个基站停运")]),_c('div',{staticClass:"table-content"},[_c('a-table',{attrs:{"size":"small","loading":_vm.loading14,"bordered":"","pagination":false,"row-key":function (r, i) {
                  return i.toString();
                },"columns":_vm.columns4,"data-source":_vm.data13}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }